<template>
  <v-form class="forma" ref="offerForm" @submit.prevent="">
    <div class="addtime__title">Add Spins</div>
    <div v-bind:class="{ hide: !isFirst }">
      <div class="addspins__subtitle">
<!--        <b>5 Minutes Guarantee</b> For Paid Spins<br />-->
<!--        Win Minimum 5 Minutes Or Even More-->
      </div>
      <img
        src="../../assets/images/spinner/buyspinner.webp"
        alt="spinner img"
        class="addspins__img"
      />
      <div class="addspins__total">
        Spins:
        <input
          v-model="form.quantity"
          label="quantity spin"
          type="number"
          min="0"
          outlined
          color="black"
          placeholder="0"
          class="addspins__total__input"
          v-validate="'required|numeric'"
          data-vv-name="quantity spin"
          :error-messages="errorsBags.collect('quantity spin')"
        />
      </div>
      <div class="addspins__total__text">
        Total: ${{ +form.quantity * +priceSpin }}
      </div>
      <div class="forma__buttons">
        <button @click="goback()">Back</button>
        <button
          @click="gonext()"
          v-bind:class="{ blockedbtn: form.quantity < 0.01 }"
        >
          {{ form.quantity > 0 ? "Next" : "Add Spins" }}
        </button>
      </div>
    </div>
    <div v-bind:class="{ hide: isFirst }">
      <div class="addspins__total__text">
        Total: ${{ +form.quantity * +priceSpin }}
      </div>
      <div v-if="this.cardNumber">
        <div class="d-flex flex-column align-center">
          <div class="saved">
            <button
              @click="
                {
                  {
                    wait ? '' : (confirmDialog = true);
                  }
                }
              "
              class="saved__use"
              :class="wait ? 'blockedbtn' : ''"
            >
              {{ wait ? "Wait" : "Use this card " + this.cardNumber }}
            </button>
            <!-- <button
              @click="confirmDeletingCard = true"
              type="submit"
              class="saved__delete"
            >
              Delete saved card
            </button> -->
          </div>
          <button
            @click="formWithNewCardIsShown = !formWithNewCardIsShown"
            class="saved__new"
            type="submit"
          >
            Use new card
          </button>
        </div>
      </div>
      <div
        v-show="!this.cardNumber || formWithNewCardIsShown"
        class="forma__all"
      >
      <div class="forma__savecard">
          <!-- <label class="check">
            <input type="checkbox" v-model="form.is_save_card" />
            <span></span>
            Save Card
          </label> -->
          <div class="forma__savecard__title">
            Credit cards saved automatically to your account after purchase
          </div>
        </div>
        <v-text-field
          v-model="form['credit_card[card_name]']"
          label="Name on Card"
          outlined
          color="black"
          class="forma__input"
          v-validate="'required'"
          data-vv-name="name card"
          :error-messages="errorsBags.collect('name card')"
        ></v-text-field>

        <v-text-field
          v-model="cardNumberWithMask"
          outlined
          color="black"
          class="forma__input"
          label="Card Number"
          v-mask="'#### #### #### ####'"
          v-validate="'required|min:18|max:19'"
          data-vv-name="card number"
          :error-messages="errorsBags.collect('card number')"
          @blur="
            form['credit_card[card_number]'] = cardNumberWithMask.replace(
              /\s+/g,
              ''
            )
          "
          append-icon="mdi-credit-card-check-outline"
        ></v-text-field>

        <div class="forma__ddmm">
          <v-text-field
            v-model="form['credit_card[month]']"
            v-mask="'##'"
            label="Month"
            v-validate="'required|numeric|min:1|max:2'"
            data-vv-name="Month"
            :error-messages="errorsBags.collect('Month')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="form['credit_card[year]']"
            v-mask="'##'"
            label="Year"
            v-validate="'required|numeric|min:2|max:2'"
            data-vv-name="Year"
            :error-messages="errorsBags.collect('Year')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="form['credit_card[cvv]']"
            v-mask="'####'"
            label="CVV"
            v-validate="'required|numeric|min:3|max:4'"
            data-vv-name="cvv"
            :error-messages="errorsBags.collect('cvv')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
        </div>

        <div class="forma__garants">
          <div class="forma__garants__title">
            Guarantee Safe Checkout Powered by CardKnox
          </div>
          <img
            src="../../assets/images/card/visa.png"
            alt=""
            class="forma__garants__img"
          />
          <img
            src="../../assets/images/card/mastercard.png"
            alt=""
            class="forma__garants__img"
          />
          <img
            src="../../assets/images/card/card.png"
            alt=""
            class="forma__garants__img"
          />
          <img
            src="../../assets/images/card/amex.png"
            alt=""
            class="forma__garants__img"
          />
        </div>

        <v-dialog v-model="confirmDialog" max-width="500px">
          <ConfirmPay
            @closeConfirmDialog="closeConfirmDialog"
            @confirmPay="buy(true)"
          />
        </v-dialog>
        <v-dialog max-width="500px" v-model="confirmDeletingCard">
          <ConfirmDeletingCard
            @closeConfirmDeletingCard="closeConfirmDeletingCard"
            @deletingSavedCard="deletingSavedCard"
          />
        </v-dialog>
        <v-dialog v-model="payCashDialog" max-width="500px">
          <CashPay
              :price=totalPrice
              :custom-fields="cashFields"
              @closeCashPayDialog="closePayCashDialog"
              @confirmPay="confirmPayCash"
          />
        </v-dialog>
      </div>
      <div class="forma__buttons">
        <button @click="isFirst = !isFirst">Back</button>
        <button
          @click="
            {
              {
                wait ? '' : buy(false);
              }
            }
          "
          :class="wait ? 'blockedbtn' : ''"
          v-show="!this.cardNumber || formWithNewCardIsShown"
        >
          {{ wait ? "Wait" : "Buy Spins" }}
        </button>
        <button v-show="cashAvailable" class="full-width-button" @click="openPayCashDialog()">Pay by cash</button>
      </div>
    </div>
  </v-form>
</template>

<script>
import ConfirmPay from "../common/modal/ConfirmPay";
import ConfirmDeletingCard from "../common/modal/ConfirmDeletingCard";
import { mapActions, mapState } from "vuex";
import CashPay from "@/components/common/modal/CashPay.vue";

export default {
  name: "BuySpinsForm",
  components: {CashPay, ConfirmPay, ConfirmDeletingCard },
  data: () => ({
    isFirst: true,
    form: {
      "credit_card[card_number]": "",
      "credit_card[card_name]": "",
      "credit_card[month]": "",
      "credit_card[year]": "",
      "credit_card[cvv]": "",
      quantity: "",
      use_saved_card: "",
      is_save_card: true,
    },
    confirmDialog: false,
    confirmDeletingCard: false,
    formWithNewCardIsShown: false,
    priceSpin: "1.99",
    cardNumberWithMask: "",
    wait: false,
    payCashDialog: false,
    cashAvailable : localStorage.cashAvailable === 'true',
  }),
  created() {
    this.fetchSpinPrice();
  },
  methods: {
    ...mapActions(["setNotification", "setCardNumber", "setSpinnerQuantity"]),
    clearForm() {
      Object.keys(this.form).forEach((key) => (this.form[key] = null));
    },
      openPayCashDialog() {
        this.payCashDialog = true;
      },
      async confirmPayCash() {
        this.clearForm();
        this.setNotification({
          show: true,
          type: "success",
          message: "your transaction was completed",
        });
        this.$emit("closeDialog");
        this.wait = false;
        this.resetForm();
        this.$router.push({ name: "spinner" });
      },
      closePayCashDialog() {
        this.payCashDialog = false;
      },
    async fetchSpinPrice() {
      try {
        const response = await this.$axios.get("/api/spinner/price");
        this.priceSpin = response?.data?.price;
      } catch (error) {
        console.error(error);
      }
    },
    async buy(isSavedCard) {
      try {
        if (isSavedCard) {
          this.form.use_saved_card = true;
        } else {
          this.form.use_saved_card = false;
        }
        let isValid = isSavedCard
          ? await this.$validator.validate("quantity spin")
          : await this.$validator.validateAll();
        if (isValid) {
          this.wait = true;
          const payload = new FormData();
          for (const key in this.form) {
            payload.append(key, this.form[key]);
          }
          const response = await this.$axios.post("api/spinner/buy", payload);
          if (response.status === 200) {
            this.clearForm();
            this.setNotification({
              show: true,
              type: "success",
              message: response.data.message,
            });
            this.$emit("closeDialog");
            this.$emit("payDone");
            this.$eventBus.$emit("updateUser", response.data?.user);
            this.setCardNumber({
              cardNumber: response.data?.user?.cardNumber,
            });
            this.setSpinnerQuantity({
              spinner_quantity: response.data?.user?.spinner_quantity,
            });
            this.wait = false;
          }
          this.wait = false;
          this.resetForm();
          this.$router.push({ name: "spinner" });
        }
      } catch (error) {
        this.wait = false;
        console.error(error);
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    resetForm() {
      this.$refs.offerForm.reset();
    },
    goback() {
      this.$router.go(-1);
    },
    gonext() {
      if (this.form.quantity > 0) {
        this.isFirst = !this.isFirst;
      }
    },
    async deletingSavedCard() {
      try {
        const response = await this.$axios.get(`/api/users/remove_credit_card`);
        if (response.status === 200) {
          this.setCardNumber({
            cardNumber: "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeConfirmDeletingCard() {
      this.confirmDeletingCard = false;
    },
  },
  computed: {
    ...mapState(["cardNumber", "userId"]),
    cashFields() {
      return {
        type: "spins",
        user_id: this.userId.toString(),
        spin_count: this.form?.quantity?.toString(),
        originalPrice: this.totalPrice.toString()
      };
    },
    totalPrice() {return +this.form?.quantity * +this.priceSpin},
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
.blockedbtn {
  background-color: #7a7a7a;
  border: none;
}
</style>
