<template>
  <v-card>
    <div class="confirm__title">Are you sure you want to pay?</div>
    <div class="confirm__buttons">
      <button class="confirm__yes" @click="confirmPay">Yes</button>
      <button class="confirm__no" @click="closeConfirmDialog">No</button>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmPay",
  data: () => ({}),
  methods: {
    closeConfirmDialog() {
      this.$emit("closeConfirmDialog");
    },
    confirmPay() {
      this.$emit("confirmPay");
      this.$emit("closeConfirmDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  &__title {
    font-size: 20px;
    font-family: "MyFont";
    color: #000;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px auto;
    gap: 5vh;
  }

  &__yes {
    max-width: 150px;
    width: 100%;
    height: 60px;
    color: #fff;
    background-color: #cdd625;
    border-radius: 10px;
  }

  &__no {
    max-width: 150px;
    width: 100%;
    height: 60px;
    color: #000;
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
  }
}
</style>
