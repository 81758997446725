<template>
  <button class="header__logout" @click="logout">Log Out</button>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ControlLogout",
  methods: {
    ...mapActions(["clearState"]),
    async logout() {
      try {
        await this.$axios.get("/api/logout");
        this.clearState();
        await this.$router.push({ name: "login" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>
