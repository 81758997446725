<template>
  <v-card>
    <div class="confirm__title">
      <div class="paycash__coin">
        <img src="../../../assets/images/cash/coin.gif" alt=""/>
        <img class="d-none"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA6VJREFUaEPtmvF12jAQxr9jAPAGTSZoNgidIMkEJROUTNB0gtAJSiYITNBkAzpBYQNgANR3MmDHyPZJlmRS0Hv5I4lk6XefdNKdRDixQifGizPw/674WWGvCqvkAsA1sLkC6AoA/84/+TIHMAfUDOjMALyBlvy3IMW/whpycwPQ0AAnhWADjIDO1De8P+AU9DtAAymVrJ4aA50fvsCbA6skATZP/kGL5tDgD6DlUmYoc61mwCq5BdQvAEmTQVi0XQJ0D1pOLNq8q+oOrLqsKq/TFooagdYPLh3bA+sprF4A9F069NhmslXbaorbAaewvwHwFnMMZQbQF5t1bQncY2Vvj4E0N4YJaHUnHZMcWHVHAH2TfjhuPfUTtBb5Exlw6o1Z3SMudCfx3vXA6br9G3HrcTUqb1mXdetZANwdA/TVdRRx26ln0LrypFcNrI+LWt0PVLTKpcFHDfBHUnenSbXK5cDtq7sA8MltapWrXAU8BNSTW4dNW9E9gAmgXgF8tv8acZAxMrWrAO7x2i0G6/Z9W7fQwcFYN0t3CBfoOWh1KQdubTrnYPdL0hXaPK3NCqtksA37rPVxb2CAzaAdlpf5eyXAsY+RlbCOxjcfN0uAe7xurt3VsmkZAlb3/wZaHYSwZcCRHFYwWAY2Oq4yYGWj0bbuH4DG8q0sKGw6JFod8PkCZti+PriLHF4E2IDAGWzmVSscTSTYQMALfaNgSp0alY4IawnM0YbwHCuFkNZz8B7mJgvQ6uCk6GlbqoNhB7I9LhYHJ1rzTkaw2ZZcDh4V0GXjDQfLB3Fjnsvz0dICOiisXsBZEJIzeBlwg0yHADo4rAY2Zj6qwkMLx1Wcs3VrWt9HhSxGh6XNUNqrShwilPzXjKGeYyBgaxunBICPBN67YD4SbPl0rlaY/6t8JPF0uoY/Fnoab6eBaxJPj9GHyrbTsWn9Jmlabyo3hZC2b5qIT1XmRBp77J6025bqrQC6aH7VkkKf0GXaTq6Tui7NoPmZwU1LU7akWzUFrcWX9PW3h/lu3BPjoWx0mHyo6ckOeO/ENnyF2rLSagp0BnVOqshvD3wUa1r+xMEfcOa9+R4o1pbFWw+r2sLDtL3S+ukhP3gJ/EpAPQOdoe0U9qvwe4fGj0sf/YNr0MfjeVxaNGH6RpoPKvyMSJgIPHCtnA3l+92JL9BdD+5OS7LRpPD9wgPxohH4pj//QPzVN2R+qGGBJUaJXOcMHNng0bs7Kxzd5JE7/AdZq4hMe95IYgAAAABJRU5ErkJggg=="
        />
        <div></div>
      </div>
      <div class="paycash__block">
        {{ msg }}
      </div>
    </div>
    <div class="confirm__buttons">
      <button ref="pay_button" class="confirm__yes" @click="confirmPay">Yes!</button>
      <!-- <button class="confirm__no" @click="closeCashPayDialog">Cancel</button> -->
    </div>
  </v-card>
</template>

<script>
import fspCashService from '../../../plugins/cash-machine';

export default {
  name: "CashPay",
  props: {
    price: {
      type: Number,
      required: true
    },
    customFields: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    msgDefault: "",
    msg: "",
    isTransactionActive: false,
    priceInt: 0,
  }),
  destroyed() {
    fspCashService.off('logs', this.logListener)
    fspCashService.off('completed', this.completeListener)  },
  created() {
    localStorage.test = "our test";
    this.priceInt = Math.ceil(this.price);
    this.msgDefault = "You above to pay $" + this.priceInt + " using cash machine, are you sure?";
    this.msg = this.msgDefault;
    fspCashService.on('logs', this.logListener)

    fspCashService.on('completed', this.completeListener)
  },
  mounted() {
  },
  methods: {
    logListener(msg) {
      // if(msg.display)
      this.msg = msg.message;
    },
    completeListener() {
      console.log(this.isTransactionActive);
      this.$refs.pay_button.disabled = false;
      this.msg = this.msgDefault
      if (this.isTransactionActive) {
        this.isTransactionActive = false;
        this.$emit("confirmPay");
      }
        this.closeCashPayDialog();
    },
    sendAmount(amount, customFields) {
      this.isTransactionActive = true;
      this.$refs.pay_button.disabled = true;
      fspCashService.sendAmount(amount, customFields);
    },
    cancelTransaction() {
      this.isTransactionActive = false;
      fspCashService.cancelTransaction();
    },
    closeCashPayDialog() {
      if (this.isTransactionActive)
        this.cancelTransaction()
      else
        this.$emit("closeCashPayDialog");
    },
    confirmPay() {
      this.sendAmount(this.priceInt, this.customFields)
    },
  },
};
</script>

<style lang="scss" scoped>

.paycash {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 55%;
  background: #fff;
  height: 85%;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  @media screen and (max-width: 992px) {
    width: 90%;
    height: 75%;
  }
  @media screen and (max-width: 500px) {
    padding: 10px;
  }

  &__coin {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto;

    img {
      max-width: 100%;
    }

    div {
      width: 100%;
      height: 20px;
      background: #c5c5c5;
      margin-bottom: 5%;
      border-radius: 15px;
    }
  }

  &__block {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    border: 1px solid #c5c5c5;
    font-weight: 600;
    font-size: 22px;
    @media screen and (max-width: 992px) {
      font-size: 20px;
      font-weight: 500;
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      align-self: flex-end;
      @media screen and (max-width: 992px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}

.confirm {
  &__title {
    font-size: 20px;
    font-family: "MyFont";
    color: #000;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px auto;
    gap: 5vh;
  }

  &__yes {
    max-width: 150px;
    width: 100%;
    height: 60px;
    color: #fff;
    background-color: #cdd625;
    border-radius: 10px;

    :disabled {
      background-color: grey;
    }
  }

  &__yes:disabled {
    background-color: grey;
  }

  &__no {
    max-width: 150px;
    width: 100%;
    height: 60px;
    color: #000;
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
  }
}

</style>
